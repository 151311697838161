
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import { WandIcon } from "vue-tabler-icons";
  import { apiRecover } from "../api";

  @Component({
    components: { LzButton, WandIcon }
  })
  export default class Recover extends Vue {
    recoverForm = {
      email: ""
    };

    loadingPostRecover = false;
    recoverCompleted = false;

    async onRecoverSubmit() {
      this.loadingPostRecover = true;

      await apiRecover({
        email: this.recoverForm.email
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(async (response: any) => {
          this.recoverCompleted = true;
        })
        .catch(this.handleError)
        .finally(() => (this.loadingPostRecover = false));
    }

    handleError(error: any): void {
      const message: string = error?.response?.data?.message ?? "";
      let text: string;
      switch (message) {
        case "MAIL_NOT_FOUND":
          text = this.$tc("auth.recover.errors.userNotFound");
          break;
        default:
          text = this.$tc("common.error.generic");
      }

      this.$notify({
        type: "error",
        text
      });
    }
  }
